export interface InvoiceSearchRequest {
  supplierId: string;
  invoiceNumber: string;
  importId: string;
  year: string;
  konto: string;
  ansvar: string;
  verksamhet: string;
  query: string;
  includeEmission: IncludeEmissionState;
  exkluderaKonto: boolean;
}
export enum IncludeEmissionState {
  All,
  Found,
  NotFound,
}