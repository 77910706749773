





































































































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";
import {
  InvoiceSearchRequest,
  IncludeEmissionState,
} from "@/models/InvoiceSearchRequest";
import { InvoiceSearchResultResponse } from "@/models/InvoiceSearchResultResponse";

@Component
export default class Suppliers extends Vue {
  public suppliers: any[] = [];
  public onlyActive: boolean = true;
  public result: InvoiceSearchResultResponse = null;
  public options: { value: IncludeEmissionState, label: string }[] = Object.keys(IncludeEmissionState) 
    .filter((key) => isNaN(Number(key)))
    .map((key) => {
      const value =
        IncludeEmissionState[key as keyof typeof IncludeEmissionState];

      let label = key;

      switch (value) {
        case IncludeEmissionState.All:
          label = "Alla";
          break;

        case IncludeEmissionState.Found:
          label = "Aktivitet funnen";
          break;

        case IncludeEmissionState.NotFound:
          label = "Aktivitet ej funnen";
          break;

        default: label = key;
      }

      return {
        label,

        value,
      };
    });
  public filter: InvoiceSearchRequest = {
    supplierId: null,
    invoiceNumber: null,
    year: null,
    importId: null,
    query: null,
    konto: null,
    ansvar: null,
    verksamhet: null,
    includeEmission: IncludeEmissionState.All,
    exkluderaKonto: false
  };

  public async getSuppliers() {
    this.suppliers = await adminService.getSuppliers(
      this.onlyActive,
      organisationModule.organisationDetails.id
    );
  }

  public async findInvoices() {
    this.result = await adminService.findInvoices(
      organisationModule.organisationDetails.id,
      this.filter
    );
  }

  public async mounted() {
    await this.getSuppliers();
  }
}
